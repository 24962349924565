import axios from 'axios'
import { Message } from 'element-ui'
// import {
//   config
// } from 'vue/types/umd'
// import store from '@/store'
import { getToken, setToken } from '@/utils/auth'
let baseURL = process.env.VUE_APP_BASE_URL
if (process.env.NODE_ENV === 'development') {
  baseURL = '/api'
}
const service = axios.create({
  baseURL,
  timeout: 10000, // request timeout
})

service.interceptors.request.use(
  (config) => {
    let token = getToken()
    if (process.env.NODE_ENV === 'development' && !token) {
      // token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSU0EifQ==.piMAGXH/8EM4ajM/xEIHgH8XjJ4q+in6ApNV7QxB1/qr5qUDTG0WLo7ukfXhsUMjIp3CU7iHWVafnOLet9XiMqv4Bj/cAVZiwhHFijA/mma5fnfeOiqVJLH47ld42hplVgy2P0da8RXWS+3xt7Cc6/wf1AGfwZdGXZGQ+RU8fDXaSSku/8cHjyvhe2mkYvOO2TODB7toX3Jn7jSWetOWUgKEY08JrptBykBXFeg11t6G0qlHyzBfaUUbkfdlcPS/y4fny/+gWCBg2mGiya4y2g==.e25e0049d7daa262b6c8a26f14c0d44f8b8aeaa4'
      token =
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJSU0EifQ==.piMAGXH/8EM4ajM/xEIHgAj3psGrMSo0IxlH7dk6uJyTM6kV6WaMcPn2/RzXoAPsv4I2yA5Gp5YSte8sattSheCpPGjPb48kq1CQ6VItYjpDdiWxxKMthOWrwvx3eOwCXvhlEiks6fOVEMb8geivwHuNJbmy2B7x7TkQBLB3xRD2V6RwuUbYwKlsUAuUSb0/EkXuWc3YKoJFQ5tpjbgDXBV/lvUH1yPuZbnihDePbkgmRxPOCAq2ZrR+rbYh/T37J6VjV3+EgXBcf5gP1gZ+SleMr3o7Um4Ih9VE+MnOb+YfUTWKsHsvSnvhhJkEaAXO.2f99280f10665fc3a9c54c2a5c83d91daff5b4d0'
      setToken(token)
    }
    if (token) {
      config.headers.accessToken = token
    }

    return config
  },
  (error) => {
    console.log(error, 'request error')
    return Promise.reject(error)
  },
)

service.interceptors.response.use(
  // eslint-disable-next-line consistent-return
  (response) => {
    const res = response.data
    if (res.code === '401') {
      Message({
        message: res.description,
        type: 'error',
        duration: 3 * 1000,
      })
      window.location.href = res.redirectUrl
      return
    }
    if (res.success === false) {
      Message({
        message: res.description,
        type: 'error',
        duration: 3 * 1000,
      })
    }
    if (res.size) return res
    // eslint-disable-next-line consistent-return
    return res.data
  },
  (error) => {
    const respose = error.response
    Message({
      message: respose.data.description,
      type: 'error',
      duration: 3 * 1000,
    })
    return Promise.reject(error)
  },
)

export default service
