import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'
import BaseLayout from '../layouts/BaseLayout.vue'
import { findListByUserId } from '@/api/manage'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: BaseLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/config',
        name: 'PayConfig',
        meta: {
          title: '支付配置',
        },
        component: () => import(/* webpackChunkName: "pay-config" */ '@/views/pay-config/index.vue'),
      },
      {
        path: '/record',
        name: 'PayOrderRecords',
        meta: {
          title: '支付记录',
        },
        component: () => import(/* webpackChunkName: "pay-order-records" */ '@/views/pay-order-records/index.vue'),
      },
      {
        path: '/record/detail',
        name: 'PayDetail',
        meta: {
          title: '支付详情',
        },
        component: () => import(/* webpackChunkName: "pay-detail" */ '@/views/pay-detail/index.vue'),
      },

      {
        path: '/rule',
        name: 'PayRules',
        meta: {
          title: '分账规则',
        },
        component: () => import(/* webpackChunkName: "pay-rules" */ '@/views/pay-rules/index.vue'),
      },
      {
        path: '/refund/list',
        name: 'RefundRecords',
        meta: {
          title: '退款申请',
        },
        component: () => import(/* webpackChunkName: "refund-records" */ '@/views/refund-records/index.vue'),
      },
      {
        path: '/refund/detail',
        name: 'RefundRecordDetail',
        meta: {
          title: '退款详情',
        },
        component: () =>
          import(
            /* webpackChunkName: "refund-record-detail" */
            '@/views/refund-record-detail/index.vue'
          ),
      },
      {
        path: '/split/list',
        name: 'SplitList',
        meta: {
          title: '分账记录',
        },
        component: () => import(/* webpackChunkName: "splite-records" */ '@/views/splite-records/index.vue'),
      },
      {
        path: '/store/balance',
        name: 'StoreBalance',
        meta: {
          title: '门店余额',
        },
        component: () => import(/* webpackChunkName: "store-balance" */ '@/views/store-balance/index.vue'),
      },
      {
        path: '/withdrawal/list',
        name: 'WithDrawalList',
        meta: {
          title: '门店提现申请',
        },
        component: () =>
          import(
            /* webpackChunkName: "withdrawal-application" */
            '@/views/withdrawal-application/index.vue'
          ),
      },
      {
        path: '/withdrawal/detail',
        name: 'WithDrawalList',
        meta: {
          title: '提现详情',
        },
        component: () =>
          import(
            /* webpackChunkName: "withdrawal-detail" */
            '@/views/withdrawal-application-detail/index.vue'
          ),
      },
      {
        path: '/dashboard',
        name: 'PayDashboard',
        meta: {
          title: '支付统计',
        },
        component: () => import(/* webpackChunkName: "pay-dashboard" */ '@/views/dashboard/index.vue'),
      },
      {
        path: '/financialFileExport',
        name: 'FinancialFileExport',
        meta: {
          title: '财务文件导出',
        },
        component: () => import(/* webpackChunkName: "pay-dashboard" */ '@/views/financialFileExport/index.vue'),
      },
      {
        path: '/receiveAuditList',
        name: 'ReceiveAuditList',
        meta: {
          title: '收款登记审核',
        },
        component: () => import('@/views/receiveAuditList/index.vue'),
      },
      {
        path: '/receiveAgencies',
        name: 'ReceiveAgencies',
        meta: {
          title: '收款机构管理',
        },
        component: () => import('@/views/receiveAgencies/index.vue'),
      },
      {
        path: '/financeAccountStatistics',
        name: 'financeAccountStatistics',
        meta: {
          title: '财务对账单',
        },
        component: () => import('@/views/finance-account-statistics/index.vue'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  // 为微应用路由加上前缀，如果不加微应用路由切换会有问题
  // eslint-disable-next-line no-underscore-dangle
  base: window.__POWERED_BY_QIANKUN__ ? '/index/pay' : process.env.BASE_URL,
  routes,
})
router.beforeEach((to, from, next) => {
  // let info = JSON.parse(window.sessionStorage.getItem("userInfo"))
  const data = store.getters.getPowerList
  console.log(data)
  if (data.length === 0) {
    try {
      findListByUserId({
        data: {
          nodeType: 2,
          nodePort: 1,
        },
      }).then((res) => {
        // window.sessionStorage.setItem("userInfo", JSON.stringify(res))
        store.dispatch('setPowerData', res)

        next()
      })
    } catch {
      next()
    }
  } else {
    next()
  }
})
export default router
