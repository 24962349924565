<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'BaseLayout'
};
</script>

<style lang="scss" scoped></style>
