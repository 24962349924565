import request from '@/utils/request';

export function getAction(url, data) {
  return request({
    url,
    method: 'GET',
    data
  })
}

export function postAction(url, data) {
  return request({
    url,
    method: 'POST',
    data
  })
}

export function deleteAction(url, data, options) {
  return request({
    url,
    method: 'POST',
    data,
    ...options
  })
}
export function findListByUserId(data) {
  return request({
    url: "/user-web-api/admin/permission/findListByUserId",
    method: 'POST',
    data,
  })
}
export default {}
