import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import businesscomponent from 'business-component-vue2'
import '@/assets/css/global.less'

import './public-path'
import './plugins'

Vue.config.productionTip = false
let instance = null

Vue.use(businesscomponent, {
  url: process.env.VUE_APP_BASE_URL,
})

function render(props = {}) {
  // container 是 qiankun 包装过的微应用容器
  const { container } = props

  instance = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount(container ? container.querySelector('#app') : '#app')
}

// 独立运行时
!window.__POWERED_BY_QIANKUN__ && render()
window.unmount = () => unmount()

export async function bootstrap() {
  console.log('[vue] vue app bootstraped')
}

export async function mount(props) {
  render(props)
}

export async function unmount() {
  instance.$destroy()
  instance.$el.innerHTML = ''
  instance = null
}
