import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    powerList: []
  },
  getters: {
    // 参数列表state指的是state数据
    getPowerList(state) {
      return state.powerList;
    }
  },
  mutations: {
    setPowerList(state, data) {
      state.powerList = data; // 将传参设置给state的city
    }
  },
  actions: {
    setPowerData({
      commit,
      state
    }, data) {
      // 跟后台打交道
      // 调用mutaions里面的方法
      commit("setPowerList", data);
    }
  },
  modules: {},
});
